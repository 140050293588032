import React from 'react';
import {
  useLocation,
  useNavigationType,
  matchRoutes,
  createRoutesFromChildren,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

if (process.env.ENVIROMENT !== 'development') {
  Sentry.init({
    dsn: 'https://64fe6a97182f8a50e875bdd9f6627579@o1177915.ingest.sentry.io/4505946876870656',
    // Integrations for Sentry error tracking
    environment: process.env.ENVIROMENT,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],

    // Build configuration for Sentry
    // build: {
    //   sourcemap: true, // Source map generation must be turned on
    // },

    // Plugins for Sentry error tracking
    plugins: [
      // Put the Sentry vite plugin after all other plugins
      // sentryVitePlugin({
      //   org: "bigg-bk",
      //   project: "javascript-react",
      //   authToken:'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',
      // }),
    ],

    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
